<template>
<div id="tips-view-questions-table" v-touch:swipe.left="SwipeLeft" v-touch:swipe.right="SwipeRight">
    <Loader v-if="loading" rows="20" cols="3"></Loader>
    <Alert v-else-if="questions.length <= 0" variant="info" >Här fanns inga frågor att visa just nu!</Alert>
    <b-container fluid v-else>
        <b-row class="mt-2 mb-4">
            <b-col cols="12" md="6" class="mb-1">
                <UsersSelect :id="uid" v-model="selected" :all="isSingleQuestion" title="Välj användare" :users="allusers"></UsersSelect>
            </b-col>
            <b-col cols="12" md="6" class="mb-1">
                <QuestionSelect :id="qid" v-on:select="QuestionSelect" showclear></QuestionSelect>
            </b-col>
        </b-row>
        <b-row no-gutters class="sticky-top bg-white tips-view-header" :class="scrolled ? 'sticky-top-shadow' : ''">
            <b-col cols="7" lg="7" class="text-left">Fråga</b-col>
            <b-col cols="1" lg="1" class="text-center d-none d-lg-block">Poäng</b-col>
            <b-col cols="5" lg="4" class="text-center">Rätt svar</b-col>
        </b-row>
        <b-row no-gutters v-for="(q, index) in questions" :key="q.id" class="tips-view-row" :class="{'text-muted' : getAnswersString(q) == '&nbsp;'}">
            <b-col cols="12" class="tips-sub-sticky border-bottom pb-1">
                <b-row no-gutters class="">
                    <b-col cols="7" lg="7" class="align-self-center">
                        <div class="tips-question-title">
                            <router-link class="text-dark" :class="{'text-muted' : getAnswersString(q) == '&nbsp;'}" :to="'/visa/fragor/'+q.id">{{ qindex? qindex+1 : index+1}}. {{q.question}}</router-link>
                        </div>
                        <div v-if="hasOptions(q)" class="font-italic text-secondary d-none d-md-block">
                            {{getOptionsString(q)}}
                        </div>
                    </b-col>
                    <b-col cols="1" class="text-center d-none d-lg-block">
                        {{q.points}}
                    </b-col>
                    <b-col cols="5" lg="4" class="tips-view-entry" :class="{'bg-light' : getAnswersString(q) != '&nbsp;'}">
                        <span v-html="getAnswersString(q)"></span>
                    </b-col>
                </b-row>
            </b-col>
            <b-col cols="12" v-for="u in users" :key="u.id" class="px-0 mx-0 border-bottom py-1">
                <b-row no-gutters class="user-answer-row" :class="{'current-user-row' : currentUser.id == u.id}">
                    <b-col cols="7" lg="7" class="pl-3 py-1 groups-team" :id="'viewquestion_user_row_' + u.id" >
                        <b-img rounded="circle" class="tips-user-image" :src="avatar(u)" />{{u.username}}
                        <UserPopover :target="'viewquestion_user_row_' + u.id" :user="{ userid: u.id, username: u.username}"></UserPopover>
                    </b-col>
                    <b-col cols="1" lg="1" class="d-none d-lg-block text-center">{{ UserAnswerCorrect(q, u.id)? q.points: "-"}} </b-col>
                    <b-col cols="5" lg="4" class="tips-view-entry" :class="UserAnswerClass(q, u.id)"><span v-html="getUserAnswer(q.id, u.id)"></span></b-col>
                </b-row>
            </b-col>
        </b-row>
    </b-container>
</div>
</template>

<script>
const Alert = () => import("@/components/miscellaneous/Alert");
const Loader = () => import("@/components/miscellaneous/Loader");
const UsersSelect = () => import("@/components/miscellaneous/UsersSelect");
const QuestionSelect = () => import("@/components/tips/view/questions/QuestionSelect");

export default {
    name: "ViewQuestionsTable",
    components: {
        Alert,
        Loader,
        UsersSelect,
        QuestionSelect
    },
    data() {
        return {
            scrolled: false,
            selected: [],
            qid: "",
            uid: "",
            qindex: false
        };
    },
    computed: {
        loading() {
            return this.$store.getters["tipsg/questionsloading"] ||
                this.$store.getters["tournamentsg/tournamentusersloading"];
        },
        questions() {
            var all = this.allquestion;
            if (this.qid) {
                var q = false;
                for (var i = 0; i < all.length; i++) {
                    q = all[i];
                    if (q.id == this.qid) {
                        this.qindex = i;
                        return [q];
                    }
                }
            }
            return all;
        },
        allquestion() {
            var data = this.$store.getters["tipsg/questions"];
            if (data) {
                return data.questions;
            }
            return [];
        },
        users() {
            var data = this.$store.getters["tournamentsg/tournamentusers"];
            if (data) {
                return data.filter((u) => {
                    return this.selected.includes(u);
                });
            }
            return [];
        },
        allusers() {
            return this.$store.getters["tournamentsg/tournamentusers"];
        },
        userAnswers() {
            var data = this.$store.getters["tipsg/questions"];
            if (data) {
                return data.answers;
            }
            return [];
        },
        currentUser() {
            return this.$store.state.auth.user;
        },
        isSingleQuestion() {
            if (this.qid && this.qid > 0)
                return true;
            return false;
        }
    },
    methods: {
        avatar(user) {
            return this.$images.userAvatar(user.id, user.imgversion);
        },
        getOptionsArray(q) {
            var opt = [];
            if (q.options && q.options.length > 0) {
                for (var i = 0; i < q.options.length; i++) {
                    var e = q.options[i];
                    opt.push(e.entry);
                }
            }
            return opt;
        },
        getOptionsString(q) {
            var opt = this.getOptionsArray(q);
            var str = opt.join(", ");
            switch (str) {
                case "ALLTEAM":
                case "GROUP":
                    return "";
                default:
                    return str;
            }
        },
        hasOptions(q) {
            var arr = this.getOptionsArray(q);
            if (arr && arr.length > 0)
                return true;
            return false;
        },
        UserAnswerClass(question, userid) {
            var qans = this.getAnswersArray(question);
            if (qans.length == 0)
                return "";
            return this.UserAnswerCorrect(question, userid) + "";
        },
        UserAnswerCorrect(question, userid) {
            var ans = this.getAnswersArray(question);
            var ua = this.getUserAnswer(question.id, userid);
            if (ans.includes(ua))
                return true;
            return false;
        },
        getAnswersArray(q) {
            var ans = [];
            if (q.answers && q.answers.length > 0) {
                for (var i = 0; i < q.answers.length; i++) {
                    var e = q.answers[i];
                    ans.push(e.entry);
                }
            }
            return ans;
        },
        getAnswersString(q) {
            var ans = this.getAnswersArray(q);
            if (ans.length > 0)
                return ans.join(", ");
            return "&nbsp;";
        },
        getUserAnswer(questionid, userid) {
            var answers = this.userAnswers;
            if (answers && answers[userid] && answers[userid][questionid]) {
                return answers[userid][questionid].answer;
            }
            return "&nbsp;";
        },
        handleScroll() {
            this.scrolled = window.scrollY > 0;
        },        
        QuestionSelect(question) {
            var url = "/visa/fragor";
            if (question)
                url += "/" + question.id;

            this.$router.push(url);
        },
        checkPage() {
            var qid = this.$route.params.id;
            if (qid) {
                this.qid = qid;
            } else {
                this.qid = "";
                this.qindex = false;
            }

            var uid = this.$route.params.uid;
            if (uid) {
                this.uid = uid;
            } else {
                this.uid = "";
            }
        },
        SwipeLeft() {
            this.swipe(true); // vänster ökar
        },
        SwipeRight() {
            this.swipe(false); // höger backar
        },
        swipe(isupp) {
            if (this.qindex || this.qindex === 0) {
                if (isupp) {
                    this.qindex++;
                    if (this.qindex >= this.allquestion.length)
                        this.qindex = false;
                } else {
                    this.qindex--;
                    if (this.qindex < 0)
                        this.qindex = false;
                }
            }

            if (this.qindex === false) {
                if (isupp)
                    this.qindex = 0;
                else
                    this.qindex = this.allquestion.length - 1;
            }

            var q = this.allquestion[this.qindex];
            this.qid = q.id;
            this.$router.push("/visa/fragor/" + q.id);
            //history.pushState({}, null, "/visa/fragor/"+q.id);
        }
    },
    watch: {
        $route(to, from) {
            if (to != from)
                this.checkPage();
        }
    },
    mounted() {
        this.checkPage();
    },
    created() {
        window.addEventListener('scroll', this.handleScroll);
    },
    destroyed() {
        window.removeEventListener('scroll', this.handleScroll);
    }
};
</script>
